import Image from 'next/image'
import React from 'react'
import Badges from './Badges'

const TrustedPatners = () => {
  return (
    <section className="bg-[#f9f9f9]  mt-[10px] md:py-12 sm:mb-[20px] font-poppins">
    <div className="w-full">
     <Badges />
    </div>
  </section>
  )
}

export default TrustedPatners
