import Navbar from "../components/Navbar";
import Head from "next/head";
import Image from "next/image";
import mocha_accounting_logo from "../public/mocha_accounting_logo.svg";
import Link from "next/link";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AutomatedInternalProcesses,
  Customize,
  StockManage,
} from "./../public/assets/customcsv";
import wave from "../public/home/wave.webp";
import homeimg from "../public/home/home.webp";
import { Poppins } from "next/font/google";
const poppins = Poppins({
  subsets: ["latin"],
  weight: ["700"],
  style: ["normal"],
  display: "swap",
});
import { faArrowRight, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";
import ScrollSection from "../components/ScrollSection";
import TrustedPatners from "../components/TrustedPatners";
export default function Home() {
  const [loading, setLoading] = useState("lazy");
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setLoading("eager");
      } else {
        setLoading("lazy");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    link.onload = () => setFontLoaded(true);
  }, []);

  return (
    <div>
      <Navbar />
      <Head>
        <title>
          Free Accounting Software in India | Best Accounting Solution in India
        </title>
        <meta
          name="description"
          content="Are you looking for free Accounting software for small-mid-enterprise business in India? Mocha Accounting provide cost errective & customizable accounting solution in India"
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
 
        <meta
          name="keywords"
          content="free accounting software, best accounting software, Mocha Accounting, invoicing, expenses"
        />
        <meta
          property="og:title"
          content="Free Accounting Software in India | Best Accounting Solution in India"
        />
        <meta
          property="og:description"
          content="Are you looking for free Accounting software for small-mid-enterprise business in India? Mocha Accounting provide cost errective & customizable accounting solution in India"
        />
        <meta property="og:image" content={mocha_accounting_logo} />
        <meta property="og:url" content="https://mochaaccounting.com/" />
        <link rel="canonical" href="https://mochaaccounting.com/" />
        <meta name="robots" content="index, follow" />

        <link rel="preload" as="image" href="/bg.svg" />
      </Head>
      <div>
        <section className="flex justify-between items-center md:items-start bg-no-repeat bg-center md:bg-[url('/bg.svg')]">
          <div className="mt-[100px] flex flex-col lg:flex-row justify-between items-center lg:items-start w-full">
            <div className="flex-1 p-5 lg:p-10 lg:text-left">
              <span className="lg:text-left font-semibold flex lg:justify-start mb-4 rounded-full overflow-hidden whitespace-nowrap">
                <span className="bg-[#CFECEC] flex items-center text-[16px] px-2 py-[16px] rounded-full">
                  Welcome to &nbsp;<b>MOCHA&nbsp;</b>Accounting&nbsp;
                  <Image
                    src={wave}
                    alt="wave"
                    width={24}
                    height={16}
                    className="inline-block ml-1"
                  />
                </span>
              </span>

              {fontLoaded ? (
                <h1
                  className={`text-[30px] md:text-[48px] mb-6 font-bold ${poppins.className}`}
                >
                  Cost-Effective, Customizable, Streamlined, Free Accounting
                  Software in india
                </h1>
              ) : (
                <h1
                  className={`text-[30px] md:text-[48px] mb-6 font-bold font-sans`}
                >
                  Loading...
                </h1>
              )}

              <div className="mb-6">
                <p className="text-[18px] md:text-[24px] font-poppins text-[#676767] mb-6">
                  Elevate your accounting game with our intuitive tools. Easily
                  manage Invoices, Expenses, Banking Transactions, and much
                  more.
                </p>

                <div className="flex flex-col items-center lg:items-start text-center lg:text-left mb-6">
                  <Link href="https://app.mochaaccounting.com/register">
                    <button
                      className="flex items-center px-6 py-3 bg-[#1e3932] text-white text-lg font-semibold rounded-full gap-2 hover:bg-black transition"
                      aria-label="try for free"
                    >
                      Try for FREE NOW
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="w-4 h-4"
                      />
                    </button>
                  </Link>
                  <p className="text-[12px] md:ml-7  ">
                    No Credit Card Required
                  </p>
                  <div className="mt-5"></div>
                </div>
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-center text-center">
              <Image
                src={homeimg}
                alt="Free Accounting Software"
                width={568}
                height={800}
                loading="eager"
                priority
              />
            </div>
          </div>
        </section>

        <TrustedPatners />
        <section className="pt-8 pb-[4%] bg-[#fcfcfc] text-[#1c352d]  font-poppins">
          <div className="md:text-center px-5  mb-8">
            <h1 className="text-[30px] md:text-[48px] font-bold leading-[56px]">
              Unlock The True Potential of Your Business with Mocha Accounting
            </h1>
          </div>

          <div className="flex flex-wrap justify-center gap-8 px-5">
            <div className="flex flex-col max-w-xs w-full border-green-700 border-[1px] rounded-xl transition-transform duration-300 ease-in-out hover:bg-[#006241] hover:text-white">
              <div className="flex items-center p-4">
                <h2 className="text-[18px] leading-[26px] font-semibold mr-4">
                  Automated Internal Processes
                </h2>
                <div className="wink-0 w-[6rem] h-[6rem]">
                  <AutomatedInternalProcesses className="w-full h-full" />
                </div>
              </div>
              <div className="p-4 text-left">
                <p className="text-[16px] ">
                  Make accounting effortless with <b>MOCHA</b>&#39;s automation
                  tools. From banking to sales to bills - set it up once, and
                  let <b>MOCHA</b> do the rest.
                </p>
              </div>
            </div>

            <div className="flex flex-col max-w-xs w-full border-green-700 border-[1px] rounded-xl transition-transform duration-300 ease-in-out hover:bg-[#006241] hover:text-white">
              <div className="flex items-center p-4">
                <h2 className="text-[18px] leading-[26px] font-semibold   mr-4">
                  Stock Management
                </h2>
                <div className="flex-shrink-0 w-[6rem] h-[6rem]">
                  <StockManage className="w-full h-full" />
                </div>
              </div>
              <div className="p-4 text-left">
                <p className="text-[16px] ">
                  Transform your business in india in india with our inventory management software. Precisely track stock movement with our intuitive
                  platform.
                </p>
              </div>
            </div>

            <div className="flex flex-col max-w-xs w-full  border-green-700 border-[1px] rounded-xl transition-transform duration-300 ease-in-out hover:bg-[#006241] hover:text-white">
              <div className="flex items-center p-4">
                <h2 className="text-[18px] leading-[26px] font-semibold mr-4">
                  Customize - We Make it a Perfect Fit
                </h2>
                <div className="flex-shrink-0 w-[6rem] h-[6rem]">
                  <Customize className="w-full h-full" />
                </div>
              </div>
              <div className="p-4 text-left">
                <p className="text-[16px] ">
                  All businesses are unique - customize Chart of Accounts,
                  Transaction Categories, and more to match indian financial standards and
                  business needs!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-[#006241] text-white w-full h-[254px] border-t border-white flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center text-center md:text-left px-5 gap-4 w-full justify-around">
            <h1 className="font-poppins text-[30px] md:text-[48px] font-bold leading-[40px] text-left">
              Everything your business needs - in one place
            </h1>

            <Link href="/scheduledemo">
              <div className="flex items-center justify-center md:justify-start w-full md:w-auto">
                <button
                  className=" top-[94px] left-[1130px] p-[24px_36px] gap-[8px] flex items-center mx-auto justify-between border border-solid rounded-full transition-transform duration-150 transform active:scale-95 "
                  aria-label="Request Demo"
                >
                  Request a demo
                  <FontAwesomeIcon
                    icon={faVideoCamera}
                    height={"16px"}
                    width={"16px"}
                    className="ml-2"
                  />
                </button>
              </div>
            </Link>
          </div>
        </section>

        <section className="bg-[#FFFFFF] py-5">
          <ScrollSection />
        </section>

        <section className="bg-[#fcfcfc] py-6 text-[#1c352d]">
          <div className=" mx-auto text-left px-5 font-poppins  md:px-8">
            <p className="text-[16px] font-semibold">TAILORED FOR YOU</p>
            <h1 className="text-[30px] md:text-[48px] font-bold leading-[46px] md:leading-[56px] max-w-[800px] mb-6">
              An Accounting Solution for Every Need and Every Business
            </h1>
          </div>

          <div className="flex flex-col md:flex-row justify-between   mx-auto gap-6 md:gap-8 lg:gap-24">
            <div className="flex-1 md:ml-8">
              <div className="border border-[#e49b0f] rounded-2xl px-5 py-5 md:px-7 md:py-10 gap-6 bg-white shadow-lg w-[98%] m-auto">
                <div className="flex items-center">
                  <Image
                    src="/home/tick.webp"
                    alt="Small scale business"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  <p className="font-bold pl-2 text-[18px]">
                    Small scale business
                  </p>
                </div>
                <p className="text-[18px] text-[#717579] pl-7 leading-[26px]">
                  Get paid on time, automate tasks, and make smart financial decisions with the best accounting software for small businesses.
                </p>

                <div className="flex items-center mt-6">
                  <Image
                    src="/home/tick.webp"
                    alt="Mid-market business"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  <p className="font-bold pl-2 text-[18px]">
                    Mid-market business
                  </p>
                </div>
                <p className="text-[18px] text-[#717579] pl-7 leading-[26px]">
                  Go global! Use multi-currency features, streamline Gst accounting and use advanced analytics tailored to indian enterprises, and customization.
                </p>

                <div className="flex items-center mt-6">
                  <Image
                    src="/home/tick.svg"
                    alt="Large enterprises"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  <p className="font-bold pl-2 text-[18px]">
                    For large enterprises
                  </p>
                </div>
                <p className="text-[18px] text-[#717579] pl-7 leading-[26px]">
                  Track all business segments in india, streamline Gst accounting, and use advanced analytics tailored to indian enterprises.
                </p>

                <div className="flex justify-center mt-10">
                  <Link href="#">
                    <button
                      className="px-8 py-4 text-[18px] font-semibold bg-[#006241] text-white rounded-full h-[65px]"
                      aria-label="Explore Features"
                    >
                      Explore Features
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex-1 px-5 md:px-0 lg:ml-[100px] lg:px-0 mt-10 md:mt-0 flex justify-center">
              <Image
                src="/home/tailored.webp"
                alt="Tailored Image"
                className="object-contain"
                width={500}
                height={500}
                loading="lazy"
                sizes="(max-width: 768px) 100vw, 500px"
                srcSet="/home/tailored-320w.webp 320w, /home/tailored-500w.webp 500w, /home/tailored-768w.webp 768w"
              />
            </div>
          </div>
        </section>

        <div className="bg-[#f2f0eb]">
          <div className="pb-4 text-center">
            <h1 className="text-[30px] md:text-[48px] font-semibold text-[#006241]">
              Overview of Mocha Accounting
            </h1>
          </div>

          <div className="flex flex-wrap justify-between mx-[100px] md:mx-[70px] pb-12">
            <div className="flex-1 mx-2">
              <ul className="list-none p-0">
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.webp"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  Sales
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                  />
                  Invoices
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  Inventory Management
                </li>
              </ul>
            </div>

            <div className="flex-1 mx-2">
              <ul className="list-none p-0">
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                  />
                  Expenses
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                  />
                  Purchase Orders
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                  />
                  Customizable Reports
                </li>
              </ul>
            </div>

            <div className="flex-1 mx-2">
              <ul className="list-none p-0">
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  Upload Bank Statements
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  Comprehensive Dashboard
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  GST Compliance
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section>
          <div className="mt-10">
            <div>
              <h1 className="text-[30px] md:text-[48px]  text-center font-bold">
                {" "}
                Frequently Asked Questions
              </h1>
            </div>
            <FAQ />
          </div>
        </section>

        <section className="mt-0 font-poppins py-8 bg-[#CFECEC]">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {/* Card 1 */}
            <div className="p-6 text-center">
              <Image
                src="/home/foot1.svg"
                alt="Free Trial"
                width={50}
                height={50}
                className="mx-auto mb-4"
                loading="lazy"
              />
              <p className="text-[28px] font-bold mb-2">Free Trial</p>
              <div className="text-[18px] mb-4">
                Start with a 30-day free trial to experience effortless
                accounting.
              </div>
              <Link href="https://app.mochaaccounting.com/register">
                <button className="border font-bold border-[#1C352D] text-[18px] text-[#1C352D] px-[26px] py-[14px] rounded-full flex items-center justify-center gap-2 mx-auto hover:bg-[#1C352D] hover:text-white transition-colors">
                  Start a Trial
                  <FontAwesomeIcon className="w-3" icon={faArrowRight} />
                </button>
              </Link>
            </div>

            {/* Card 2 */}
            <div className="p-6 text-center">
              <Image
                src="/home/foot2.svg"
                alt="Request a Demo"
                width={50}
                height={45}
                className="mx-auto mb-4"
                loading="lazy"
              />
              <p className="text-[28px] font-bold mb-2">Request a Demo</p>
              <div className="text-[18px] mb-4">
                Schedule a personal demo with a{" "}
                <span className="font-bold">MOCHA</span> product expert.
              </div>
              <Link href="/scheduledemo">
                <button className="border font-bold border-[#1C352D] text-[18px] text-[#1C352D] px-[26px] py-[14px] rounded-full flex items-center justify-center gap-2 mx-auto hover:bg-[#1C352D] hover:text-white transition-colors">
                  Request a Demo
                  <FontAwesomeIcon className="w-3" icon={faArrowRight} />
                </button>
              </Link>
            </div>

            {/* Card 3 */}
            <div className="p-6 text-center">
              <Image
                src="/home/foot3.svg"
                alt="Plans & Pricing"
                width={50}
                height={45}
                className="mx-auto mb-4"
                loading="lazy"
              />
              <p className="text-[28px] font-bold mb-2">Plans & Pricing</p>
              <div className="text-[18px] mb-4">
                Compare plans and features and find the best fit for your needs.
              </div>
              <Link href="/pricing">
                <button className="border font-bold border-[#1C352D] text-[18px] text-[#1C352D] px-[26px] py-[14px] rounded-full flex items-center justify-center gap-2 mx-auto hover:bg-[#1C352D] hover:text-white transition-colors">
                  View all Plans
                  <FontAwesomeIcon className="w-3" icon={faArrowRight} />
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
