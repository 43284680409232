import { useRef, useCallback } from "react";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";

const ScrollSection = () => {
  const cardContainerRef = useRef(null);

  const scrollToPosition = useCallback(scrollLeft => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollTo({
        left: scrollLeft,
        behavior: "smooth"
      });
    }
  }, []);

  const scrollLeft = useCallback(
    () => {
      if (cardContainerRef.current) {
        const currentScrollLeft = cardContainerRef.current.scrollLeft;
        const newScrollLeft = currentScrollLeft - 300;

        if (newScrollLeft <= 0) {
          scrollToPosition(cardContainerRef.current.scrollWidth);
        } else {
          scrollToPosition(newScrollLeft);
        }
      }
    },
    [scrollToPosition]
  );

  const scrollRight = useCallback(
    () => {
      if (cardContainerRef.current) {
        const currentScrollLeft = cardContainerRef.current.scrollLeft;
        const newScrollLeft = currentScrollLeft + 300;

        if (
          newScrollLeft + cardContainerRef.current.offsetWidth >=
          cardContainerRef.current.scrollWidth
        ) {
          scrollToPosition(0);
        } else {
          scrollToPosition(newScrollLeft);
        }
      }
    },
    [scrollToPosition]
  );

  return (
    <div>
      <section className="bg-[#FFFFFF] py-5 font-poppins">
        <div>
          <div className="relative flex items-center">
            <button
              className="absolute left-0 z-5 bg-yellow-600 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
              onClick={scrollLeft}
              aria-label="scroll left"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>

            <div
              className="flex overflow-x-scroll scrollbar-hide space-x-[63px] py-5 px-4 text-[#0A0B0A]"
              ref={cardContainerRef}
            >
              {/* Card 1: Receivables */}
              <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] border border-[#DBDBDB] rounded-3xl">
                <Image
                  height={300}
                  width={400}
                  src="/home/receivables.webp"
                  alt="Receivables"
                  loading="lazy"
                />
                <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">
                  Receivables
                </h2>
                <p className="text-[18px] font-normal leading-[30px] text-center mt-2 font-poppins">
                  Raise tax-compliant, professional invoices in no time.
                  Automate invoices, set reminders, and more.
                </p>
                <div className="flex-grow" />
                {/* <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                                </span> */}
              </div>

              {/* Card 2: Payables */}
              <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px]  border border-[#DBDBDB] rounded-3xl">
                <Image
                  height={300}
                  width={400}
                  src="/home/feat2.webp"
                  alt="Payables"
                  loading="lazy"
                />
                <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">
                  Payables
                </h2>
                <p className="text-[18px] font-normal leading-[30px] text-centerfont-poppins mt-2">
                  Track vendor bills and other expenses in india without any
                  fuss. Manage vendor credits, and more Gst Reconiliation
                  easily.
                </p>
                <div className="flex-grow" />
                {/* <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                                </span> */}
              </div>

              {/* Card 3: Robust Dashboard */}
              <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px]  border border-[#DBDBDB] rounded-3xl">
                <Image
                  height={300}
                  width={400}
                  src="/home/feat1.webp"
                  alt="Robust Dashboard"
                  loading="lazy"
                />
                <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">
                  Robust Dashboard
                </h2>
                <p className="text-[18px] font-normal leading-[30px] text-center font-poppins mt-2">
                  Harness the power of data with the best accounting software in
                  india. Analvze Gain valuable insights into your financial
                  performance, track key metrics, and drive decisions for
                  growth.
                </p>
                <div className="flex-grow" />
                {/* <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                                </span> */}
              </div>

              {/* Card 4: User Groups */}
              <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] border border-[#DBDBDB] rounded-3xl">
                <Image
                  height={300}
                  width={400}
                  src="/home/usergroups.webp"
                  alt="User Groups"
                  loading="lazy"
                />
                <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">
                  User Groups
                </h2>
                <p className="text-[18px] font-normal leading-[30px] text-center font-poppins mt-2">
                  Streamline your financial processes by working as a team.
                  Assign specific roles and permissions to team members,
                  ensuring everyone has access to the information they need.
                </p>
                <div className="flex-grow" />
                {/* <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                                </span> */}
              </div>

              {/* Card 5: Security & Privacy */}
              <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px]  border border-[#DBDBDB] rounded-3xl">
                <Image
                  height={300}
                  width={400}
                  src="/home/security.webp"
                  alt="Security & Privacy"
                  loading="lazy"
                />
                <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">
                  Security & Privacy
                </h2>
                <p className="text-[18px] font-normal leading-[30px] text-center font-poppins mt-2">
                  We use state-of-the-art encryption technology to protect your
                  data in india. Our commitment to safeguarding your data is reflected in
                  every aspect of our app, ensuring you can manage your finances
                  with complete confidence.
                </p>
                <div className="flex-grow" />
                {/* <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                                </span> */}
              </div>
            </div>

            <button
              className="absolute right-0 z-5 bg-yellow-600 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
              onClick={scrollRight}
              aria-label="scroll right"
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScrollSection;
